import React from 'react';
import Contact from '../components/Contact';
import Sponsors from '../components/sections/Sponsors';
import HeaderContact from '../components/ui/HeaderContact';

const ContactPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-16">
            <HeaderContact />
            <main>
                <Contact />
                <Sponsors />
            </main>
        </div>
    );
};

export default ContactPage;
