import React from 'react';
import { useAuth } from '../hooks/auth.js';

const WelcomeAlienMessage = () => {
    const { authData, isValid } = useAuth();

    if (!isValid) return null;

    return (
        <div className="bg-rose-100 min-h-screen py-16">
            <main>
                <h1 className="text-4xl text-center">Welcome To EpicEvils!</h1>
                {authData && authData.role ? (
                    <p className="text-center">
                        Congratulations, you are now a {authData.role}!
                    </p>
                ) : null}
                {authData && !authData.role ? (
                    <p className="text-center">
                        Error al obtener tu rol en PocketBase. Por favor, contáctanos.
                    </p>
                ) : null}
                <button
                    className="block mx-auto mt-4 bg-rose-500 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => window.location.href = 'https://discord.com'}
                >
                    Discord
                </button>
            </main>
        </div>
    );
};

export default WelcomeAlienMessage;