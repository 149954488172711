
import React from 'react';

const NewsRoomCollab = () => {
    return (
        <div className="flex flex-col space-y-4 bg-rose-300 text-red-600 p-4 text-center">
            <a href="https://news.pencil.li/posts/epicevils-x-inzoi" target="_blank" rel="noreferrer">
                <h2 className="text-3xl font-bold">Newsroom &rarr;</h2>
                <p className="text-xl">Read the latest news about our collaboration on Pencil News.</p>
            </a>
        </div>
    );
};

export default NewsRoomCollab;