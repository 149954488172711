import React from 'react';

const HeaderSection = () => {
    return (
        <header className="bg-rose-100 text-[#f00e33] py-6">
            <div className="flex flex-col items-center mx-auto">
                <h1 className="flex flex-row font-ryomen items-center">
                    <span className="text-3xl ">EpIC EVILs</span>
                    <span className="text-sm pt-4 pl-2">©</span>
                </h1>
                <p className="font-bold text-lg mt-2 pr-2 pl-2">The first eSports team dedicated</p>
                <p className="font-bold text-lg pr-2 pl-2">to Pokémon TCG in Spain</p>
            </div>
        </header>
    );
};

export default HeaderSection;
