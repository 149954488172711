import React from 'react';

const clothesParts = [
    {
        equipaciones: 'https://rgkk0kg.app-palma.teide.app/api/files/h5ez3upg6fczoxi/fgzt5futmg3qhat/equipaciones_3J9K84655P.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTc0NTIwNzUsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.6iHjpQsZqsR5CT3JhqbhDFsYOkb446sXIIE4kPxOBPg',
    }
];


const Team = () => {
    return (
        <div className="container mx-auto py-12 px-4 md:px-8">
            <section className="flex flex-wrap justify-center">
                {clothesParts.map((member, index) => (
                    <div key={index} className="max-w-full rounded overflow-hidden m-4 bg-[#f00e3305] border border-2 border-[#f00e33]"> {/* max-w-sm rounded overflow-hidden m-4 bg-[#f00e3305] border border-2 border-[#f00e33] */}
                        <img className="p-4 bg-white" src={member.equipaciones} alt={member.name} />
                    </div>
                ))}
            </section>
        </div>
    );
};

export default Team;

/* 
Alejandro
https://raw.githubusercontent.com/pencil-li/epicevilsassets/d1578fa5ecb822c9428c6cfecf697ff438eb3a24/com/events/010124/1000/alejandro.jpg?token=GHSAT0AAAAAACP7WCOCOOX2337JTJQLUPJMZSLDGGQ
*/