import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-rose-100 font-medium text-[#f00e33] py-6 border-t border-[#f00e33]">
            <div className="container mx-auto flex flex-wrap justify-center p-4">
                <div className="flex flex-col md:flex-row justify-center">
                    <div className="md:mr-20 lg:mr-10 text-center">
                        <ul className="list-none mt-2">
                            <p className="text-lg">Follow us</p>
                            <a href="https://twitter.com/epicevils" className="text-[#f00e33] hover:text-[#f00e3330]" target="_blank" rel="noreferrer"> @EpicEvils</a>
                        </ul>
                    </div>
                    <div className="md:mr-20 lg:mr-10 text-center">
                        <ul className="list-none mt-2">
                            <li><a href="/" className="text-[#f00e33] hover:text-[#f00e3330]">Home</a></li>
                            <li><a href="/collab" className="text-[#f00e33] hover:text-[#f00e3330]">Collab</a></li>
                            <li><a href="/team" className="text-[#f00e33] hover:text-[#f00e3330]">Team</a></li>
                            <li><a href="/standings" className="text-[#f00e33] hover:text-[#f00e3330]">Standings</a></li>
                            <li><a href="/clothes" className="text-[#f00e33] hover:text-[#f00e3330]">Clothes</a></li>
                        </ul>
                    </div>
                    <div className="md:mr-20 lg:mr-10 text-center">
                        <ul className="list-none mt-2">
                            <li><a href="https://tcgse.com" className="text-[#f00e33] hover:text-[#f00e3330]" target="_blank" rel="noreferrer">TCGSE</a></li>
                            <li><a href="https://deckmaker.tcgse.com" className="text-[#f00e33] hover:text-[#f00e3330]" target="_blank" rel="noreferrer">DeckMaker</a></li>
                        </ul>
                    </div>
                    <div className="md:mr-20 lg:mr-10 text-center">
                        <ul className="list-none mt-2">
                            <li><a href="/privacypolicy" className="text-[#f00e33] hover:text-[#f00e3330]" target="_blank" rel="noreferrer">Privacy</a></li>
                            <li><a href="/terms" className="text-[#f00e33] hover:text-[#f00e3330]" target="_blank" rel="noreferrer">Terms of use</a></li>
                            <li><a href="/contact" className="text-[#f00e33] hover:text-[#f00e3330]">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="md:mr-20 lg:mr-10 text-center">
                <div className="mt-4">
                    <p>
                        Copyright &copy; {currentYear} EpicEvils.
                    </p>
                    <p>
                        All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;