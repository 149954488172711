import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="bg-rose-100 fixed w-full z-10 border-b border-[#f00e33]">
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex justify-between items-center py-4">
                    <Link to="/" className="flex flex-row font-ryomen items-center text-[#f00e33] hover:text-[#f00e3330]">
                        <div className="flex flex-row font-ryomen items-center">
                            <span className="text-3xl ">EpIC EVILs</span>
                            <span className="text-sm pt-4 pl-2">©</span>
                        </div>
                    </Link>
                    <div className="hidden md:flex space-x-4 font-bold sm:space-x-8 md:hidden lg:block xl:space-x-20">
                        <Link to="/collab" className="text-md text-[#f00e33] hover:text-[#f00e3330]">EpicEvils x inZoi</Link>
                    </div>
                    <div className="hidden md:flex space-x-4 font-bold">
                        <Link to="/team" className="text-md text-[#f00e33] hover:text-[#f00e3330]">Team</Link>
                        <Link to="/clothes" className="text-md text-[#f00e33] hover:text-[#f00e3330]">Clothes</Link>
                        <Link to="/standings" className="text-md text-[#f00e33] hover:text-[#f00e3330]">Standings</Link>
                        <Link to="https://tcgse.com" target="_blank" rel="noreferrer" className="text-md text-[#f00e33] hover:text-[#f00e3330]">TCGSE</Link>
                        <Link to="https://deckmaker.tcgse.com" target="_blank" rel="noreferrer" className="text-md text-[#f00e33] hover:text-[#f00e3330]">DeckMaker</Link>
                        <Link to="/contact" className="text-md text-[#f00e33] hover:text-[#f00e3350]">Contact</Link>
                    </div>
                    <div className="md:hidden">
                        <button onClick={() => setIsOpen(!isOpen)} className="outline-none mobile-menu-button">
                            <svg className="w-6 h-6 text-[#f00e33] hover:text-[#f00e3330]"
                                fill="bg-rose-100"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="md:hidden bg-rose-200 shadow-lg font-bold space-y-4">
                    <div className="flex flex-col py-4">
                        <Link to="/collab" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">EpicEvils x inZoi</Link>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <Link to="/team" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">Team</Link>
                        <Link to="/clothes" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">Clothes</Link>
                        <Link to="/standings" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">Standings</Link>
                        <Link to="https://tcgse.com" target="_blank" rel="noreferrer" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">TCGSE</Link>
                        <Link to="https://deckmaker.tcgse.com" target="_blank" rel="noreferrer" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">DeckMaker</Link>
                        <Link to="/contact" className="block text-md px-4 py-2 text-[#f00e33] hover:text-[#f00e3330]">Contact</Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
