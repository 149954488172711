import React from 'react';

const teamMembers = [
    {
        name: 'Alejandro Llamas',
        role: 'Masters',
        description: 'Core member of EpicEvils. Alejandro is a skilled Pokémon TCG player with a passion for the game.',
        last3standings: [4, 1, 12],
        last3totals: [29, 7, 21],
        image: '/alejandro.jpg',
        link: '/team'
    }
];

const TeamSection = () => {
    return (
        <section className="mb-12">
            <h3 className="text-3xl font-bold mb-8 text-center">Our Team</h3> {/* text-[#f00e33] */}
            <div className="flex flex-wrap justify-center">
                {teamMembers.map((member, index) => (
                    <div key={index} className="flex flex-col sm:flex-row max-w-3xl rounded-lg overflow-hidden m-4 bg-[#f00e3305] border border-2 border-[#f00e33]">
                        <img className="hidden" src={member.image} alt={member.name} />
                        <div className="w-full sm:w-2/3 p-6 flex flex-col justify-between">
                            <div>
                                <h4 className="text-xl font-bold text-gray-900">{member.name}</h4>
                                <p className="text-gray-700 font-medium mb-2">{member.role}</p>
                                <p className="text-gray-700 font-medium">{member.description}</p>
                                <div className="mt-4 flex justify-between font-medium">
                                    <p className="text-gray-700">Last 3 Standings:</p>
                                    <div className="flex">
                                        {member.last3standings.map((standing, idx) => (
                                            <span key={idx} className="text-gray-700 mx-2">{standing} of {member.last3totals[idx]}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <a href={member.link} className="mt-4 text-[#f00e33] hover:text-[#f00e3330] font-bold text-left">Read more</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TeamSection;
