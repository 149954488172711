import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

const pb = new PocketBase('https://w8ko4g8ckg0cc0ssw4s44kk4.app-palma.teide.app');

export const useAuth = () => {
    const [authData, setAuthData] = useState(null);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const token = window.location.href.split('?').pop();
        if (!token) return;

        pb.collection('users')
            .authWithOAuth2Code(token)
            .then((data) => setAuthData(data))
            .catch((error) => console.error(error))
            .finally(() => setIsValid(true));
    }, []);

    return { authData, isValid };
};