import React from 'react';
import BestStandings from '../components/sections/Standings';
import ToolsSection from '../components/sections/Tools';
import TeamSection from '../components/sections/Team';
import HeaderSection from '../components/ui/HeaderHome';
import Sponsors from '../components/sections/Sponsors';

const LandingPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-16">
            <HeaderSection />
            <main className="flex flex-col mx-auto">
                <BestStandings />
                <ToolsSection />
                <TeamSection />
                <Sponsors />
            </main>
        </div>
    );
};

export default LandingPage;
