import React from 'react';
import WelcomeNonAlienMessage from '../components/elements/WelcomeNonAlienMessage.js';
import Sponsors from '../components/sections/Sponsors';

const ResultsPage = () => {
    return (
        <div className="bg-rose-100 min-h-screen py-16">
            <main>
                <WelcomeNonAlienMessage />
                <Sponsors />
            </main>
        </div>
    );
};

export default ResultsPage;