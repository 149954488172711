import React from 'react';

const HederTeam = () => {
    return (
        <header className="bg-rose-200 text-[#f00e33] py-6">
            <div className="flex flex-col items-center mx-auto text-center py-4">
                <h1 className="flex flex-row font-ryomen items-center">
                    {/* <span className="text-3xl">EpIC EVILs</span>
                        <span className="text-sm pt-4 pl-2">©</span> */}
                    <span className="text-3xl px-2">CLOtHEs</span>
                </h1>
                <p className="font-bold text-lg mt-2">EpicEvils x TCGSE x PylarAI</p>
            </div>
        </header>
    );
};

export default HederTeam;
