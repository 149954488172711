import React from 'react';

const ToolsSection = () => {
    return (
        <section className="mb-12">
            <h3 className="text-3xl font-bold mb-8 text-center">Our Tools</h3> {/* text-[#f00e33] */}
            <div className="flex flex-wrap justify-center">
                <div className="flex flex-col max-w-sm rounded overflow-hidden m-4 bg-[#f00e3310] border-[#f00e33] border-2 p-6">
                    <h4 className="flex font-bold text-xl mb-2">
                        <img className="w-8 mr-2 rounded-full" src="https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/rtbuueakjkykt1m/logo192_9_gxn55_gt_w8_ny9g1rsots_ifbtrvks_mj_0aocmvWLhS.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NjQ4NDgsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.6AB4IhOh92BOUpH9JA0A1PIaFqY4Iwhg-pnnDz_E-k4" alt="TCGSE" />
                        DeckMaker</h4>
                    <p className="text-black font-medium mb-2">
                        An interactive application for creating and searching for deck information.
                    </p>
                    <a href="https://deckmaker.epicevils.com"
                        target="_blank" rel="noreferrer"
                        className="flex w-full items-center justify-center border-[#f00e33] border-2 hover:bg-[#f00e3320] text-[#f00e33] hover:text-[#f00e33] font-bold py-2 px-4 rounded mt-4 hover:bg-[#f00e3330] hover:border-[#f00e3330]"
                    >Go to DeckMaker</a>
                </div>
                <div className="flex flex-col max-w-sm rounded overflow-hidden m-4 bg-[#f00e3310] border-[#f00e33] border-2 p-6">
                    <h4 className="flex font-bold text-xl mb-2">
                        <img className="w-8 mr-2 rounded-full" src="https://rgkk0kg.app-palma.teide.app/api/files/r0dri7mnops9if2/rtbuueakjkykt1m/logo192_9_gxn55_gt_w8_ny9g1rsots_ifbtrvks_mj_0aocmvWLhS.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NjQ4NDgsImlkIjoiMWZta3h3aGdxamFjdXMwIiwidHlwZSI6ImFkbWluIn0.6AB4IhOh92BOUpH9JA0A1PIaFqY4Iwhg-pnnDz_E-k4" alt="TCGSE" />
                        TCGSE</h4>
                    <p className="text-black font-medium mb-2">A trading card game platform to find updated prices.
                    </p>
                    <a href="https://tcgse.com"
                        target="_blank" rel="noreferrer"
                        className="flex w-full items-center justify-center border-[#f00e33] border-2 hover:bg-[#f00e3320] text-[#f00e33] hover:text-[#f00e33] font-bold py-2 px-4 rounded mt-4 hover:bg-[#f00e3330] hover:border-[#f00e3330]"
                    >Go to TCGSE</a>
                </div>
            </div>
        </section>
    );
};

export default ToolsSection;

/*
TCGSE.com
https://github.com/pencil-li/epicevilsassets/blob/79f3a9ca031fca12122e99acbe228ebfc811a81c/com/sponsors/tcgse.jpg
*/