import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import { toast } from 'wc-toast';

const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [errorCount, setErrorCount] = useState(0);
    const pb = new PocketBase('https://rgkk0kg.app-palma.teide.app');

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const data = { name, email, message };
            const record = await pb.collection('contact').create(data);
            toast('Message sent!', {
                icon: { type: 'custom', content: '🎉' },
                theme: {
                    type: 'custom',
                    style: { background: 'royalblue', color: 'white' },
                },
            });
            console.log('Record created:', record);
            setName('');
            setEmail('');
            setMessage('');
            setSubmitted(true);
            setTimeout(() => {
                window.location.href = '/'; // Redirect to Home after 5 seconds
            }, 1000);
        } catch (err) {
            setErrorCount(prevCount => prevCount + 1);
            toast.error('You already submitted a form!');
            if (errorCount + 1 >= 2) {
                setSubmitted(true);
                setTimeout(() => {
                    window.location.href = '/'; // Redirect to Home after 1 second
                }, 1000);
            }
        }
    }

    return (
        <div className="p-4">
            <wc-toast></wc-toast>
            {submitted ? (
                <div className="text-center">
                    <p className="text-xl font-medium text-green-600">Thanks for contacting, redirecting to Home in 5 seconds</p>
                    <div className="flex justify-center mt-4">
                        <div className="loader">
                            <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)"><path d="m4.5 1.5c-2.41169541 1.37786776-4 4.02354835-4 7 0 4.418278 3.581722 8 8 8m4-1c2.2866288-1.4081018 4-4.1175492 4-7 0-4.418278-3.581722-8-8-8" /><path d="m4.5 5.5v-4h-4" /><path d="m12.5 11.5v4h4" /></g></svg>
                        </div>
                    </div>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="py-8 max-w-xl mx-auto">
                    <div className="mb-6">
                        <label htmlFor="name" className="block text-xl font-medium text-[#f00e33]">Name*</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            onChange={e => setName(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="email" className="block text-xl font-medium text-[#f00e33]">Email*</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            onChange={e => setEmail(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="message" className="block text-xl font-medium text-[#f00e33]">Message*</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            required
                            onChange={e => setMessage(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-rose-300 font-medium rounded-md focus:outline-none focus:ring-rose-900 focus:border-rose-900 sm:text-xl"
                        ></textarea>
                    </div>
                    <button type="submit"
                        className="flex w-full items-center justify-center border-[#f00e33] border-2 hover:bg-[#f00e3320] text-[#f00e33] hover:text-[#f00e33] font-bold py-2 px-4 rounded mt-4 hover:bg-[#f00e3330] hover:border-[#f00e3330]"
                    >
                        Send Message
                    </button>
                </form>
            )}
        </div>
    );
};

export default ContactPage;
