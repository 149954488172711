import React from 'react';
import NewsRoomCollab from '../banners/NewsRoomCollab.js';

const Gallery = () => {
  const images = [
    { id: 0, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/006.jpg' },
    { id: 1, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/004.jpg' },
    { id: 2, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/005.jpg' },
    { id: 3, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/002.jpg' },
    { id: 4, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/007.jpg' },
    { id: 5, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/008.jpg' },
    { id: 6, src: 'https://raw.githubusercontent.com/PylarAI/webassets/main/epicevils/001.jpg' },
  ];

  return (
    <div className="bg-rose-200 text-red-600 py-6">
      <NewsRoomCollab />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {images.slice(0, 3).map((image) => (
          <div key={image.id} className="relative overflow-hidden group">
            <img
              src={image.src}
              alt={`Gallery image ${image.id}`}
              className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-105"
            />
          </div>
        ))}
      </div>
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="order-1">
          <img
            src={images[3].src}
            alt="About the Collaboration"
            className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
          />
        </div>
        <div className="flex flex-col justify-center font-bold order-2 p-4 space-y-4">
          <h3 className="text-2xl">About the Collaboration</h3>
          <p className="text-xl">
            EpicEvils and inZoi have joined forces to create unique eSports-inspired clothing. Join us on this exciting journey!
          </p>
        </div>
        <div className="order-3 lg:order-4">
          <img
            src={images[4].src}
            alt="Our Vision"
            className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
          />
        </div>
        <div className="flex flex-col justify-center font-bold order-4 lg:order-3 p-4 space-y-4">
          <h3 className="text-2xl">Our Vision</h3>
          <p className="text-xl">
            We push design boundaries to create stunning and functional eSports apparel. Our collaboration with inZoi aims to reach new heights.
          </p>
        </div>
        <div className="order-5">
          <img
            src={images[5].src}
            alt="The Process"
            className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
          />
        </div>
        <div className="flex flex-col justify-center font-bold order-6 p-4 space-y-4">
          <h3 className="text-2xl">The Process</h3>
          <p className="text-xl">
            Meticulous planning and collaboration ensure high standards. Our team works closely with inZoi Beta to bring our vision to life.
          </p>
        </div>
        <div className="order-7 lg:order-8">
          <img
            src={images[6].src}
            alt="Join Us"
            className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
          />
        </div>
        <div className="flex flex-col justify-center font-bold order-8 lg:order-7 p-4 space-y-4">
          <h3 className="text-2xl">Join Us</h3>
          <p className="text-xl">
            We are the first eSports team on inZoi. Although the beta is closed, all submitted clothes will be available from day one. Stay tuned!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gallery;