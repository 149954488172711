import React from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';

const LoginButton = () => {
    const navigate = useNavigate();

    const handleLoginClick = async () => {
        const pb = new PocketBase('https://w8ko4g8ckg0cc0ssw4s44kk4.app-palma.teide.app');
        try {
            const authData = await pb.collection('users').authWithOAuth2({
                provider: 'discord',
                code: window.location.href,
                codeVerifier: '',
                redirectUrl: 'https://discord.com/oauth2/authorize?client_id=1275742586992332912&redirect_uri=https%3A%2F%2Fw8ko4g8ckg0cc0ssw4s44kk4.app-palma.teide.app%2Fapi%2Fcollections%2Fusers%2Fauth-with-oauth2',
                createData: {
                    role: '🛂 Non Alien Resident', // assign a default role
                },
            });
            navigate('/welcome-non-alien-resident');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <button onClick={handleLoginClick}>Login with Discord</button>
    );
};

export default LoginButton;